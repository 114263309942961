import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  SocialMedia,
  Image,
  Content,
  CallToActionBanner,
  OutboundLink,
  Call,
  PaginatedGrid,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      externalOrderOnlinePage,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"home-hero desktop-container"}
            >
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
                background={true}
                forceChildren={true}
              >
                <div className={"welcome-content-container desktop-only"}>
                  <Grid className={"welcome-content"} stackable={true}>
                    <Grid.Row verticalAlign={"middle"}>
                      <Grid.Column
                        width={8}
                        textAlign={"left"}
                        className={"title-column"}
                      >
                        <Header as={"h2"} content={fishermanBusiness.name} />
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        textAlign={"right"}
                        className={"social-media-column"}
                      >
                        <SocialMedia
                          socialMediaValues={fishermanBusiness.socialMedia}
                          buttonType={"circle"}
                          centerHeader={false}
                          colors={"white"}
                          groupButtons={false}
                          groupVertically={false}
                          inverted={true}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={16} className={"welcome-copy"}>
                      <div>
                        <Image
                          size={"tiny"}
                          centered={false}
                          src={
                            "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/Logo_Vector_file_transparent_uFekPny.png"
                          }
                        />
                      </div>
                      <Content stackable={true} textAlign={"left"}>
                        <Content.Markup
                          width={16}
                          isMarkdown={true}
                          content={fishermanBusiness.aboutMarkdown}
                        />
                      </Content>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CallToActionBanner stackable={true}>
                        <CallToActionBanner.CTA>
                          <Button
                            as={OutboundLink}
                            to={externalOrderOnlinePage.url}
                            primary={true}
                            size={"large"}
                          >
                            Order Online
                          </Button>
                        </CallToActionBanner.CTA>
                        <CallToActionBanner.CTA>
                          <Call
                            phoneNumber={
                              fishermanBusiness.primaryLocation.phoneNumber
                            }
                            as={"button"}
                            buttonColor={"primary"}
                          />
                        </CallToActionBanner.CTA>
                      </CallToActionBanner>
                    </Grid.Column>
                  </Grid>
                </div>
                <div className={"mobile-only"}>
                  <Header
                    content={fishermanBusiness.name}
                    as={"h1"}
                    inverted={true}
                  />
                  <Button
                    as={OutboundLink}
                    to={externalOrderOnlinePage.url}
                    primary={true}
                    size={"large"}
                  >
                    Order Online
                  </Button>
                </div>
              </Background.Image>
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid stackable={true} className={"home-hero mobile-only"}>
            <Grid.Column>
              <div className={"welcome-content-container desktop"}>
                <Grid className={"welcome-content"} stackable={true}>
                  <Grid.Row verticalAlign={"middle"}>
                    <Grid.Column
                      width={8}
                      textAlign={"left"}
                      className={"title-column"}
                    >
                      <Header as={"h2"} content={fishermanBusiness.name} />
                    </Grid.Column>
                    <Grid.Column
                      width={8}
                      textAlign={"right"}
                      className={"social-media-column"}
                    >
                      <SocialMedia
                        socialMediaValues={fishermanBusiness.socialMedia}
                        buttonType={"circle"}
                        centerHeader={false}
                        colors={"white"}
                        groupButtons={false}
                        groupVertically={false}
                        inverted={true}
                        labelPosition={"free"}
                        showLabels={false}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Column width={16} className={"welcome-copy"}>
                    <div>
                      <Image
                        size={"tiny"}
                        centered={false}
                        src={
                          "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/Logo_Vector_file_transparent_uFekPny.png"
                        }
                      />
                    </div>
                    <Content stackable={true} textAlign={"left"}>
                      <Content.Markup
                        width={16}
                        isMarkdown={true}
                        content={fishermanBusiness.aboutMarkdown}
                      />
                    </Content>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <CallToActionBanner stackable={true}>
                      <CallToActionBanner.CTA>
                        <Button
                          as={OutboundLink}
                          to={externalOrderOnlinePage.url}
                          primary={true}
                          size={"large"}
                        >
                          Order Online
                        </Button>
                      </CallToActionBanner.CTA>
                      <CallToActionBanner.CTA>
                        <Call
                          phoneNumber={
                            fishermanBusiness.primaryLocation.phoneNumber
                          }
                          as={"button"}
                          buttonColor={"primary"}
                        />
                      </CallToActionBanner.CTA>
                    </CallToActionBanner>
                  </Grid.Column>
                </Grid>
              </div>
            </Grid.Column>
          </Grid>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Button
                as={OutboundLink}
                to={"https://resy.com/cities/bss/the-bright-star "}
                primary={true}
                size={"large"}
                className={"resy-cta"}
              >
                Book a Reservation
              </Button>
            </Grid.Column>
            <Grid.Column>
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
            <Grid.Column>
              <PaginatedGrid
                rows={2}
                columns={2}
                className={"reviews"}
                tight={true}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={200} />,
                  propMap: { author: "author", text: "text" },
                })}
              />
              <Grid
                stackable={true}
                verticalAlign={"top"}
                centered={true}
                column={"2"}
              >
                <Grid.Row textAlign={"center"}>
                  <Grid.Column textAlign={"center"}>
                    <h2>Awards</h2>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched={true}>
                  <Grid.Column width={6} textAlign={"center"}>
                    <Image
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/JamesBeardAward1.jpg"
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={6} textAlign={"center"}>
                    <Image
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/CNN.png"
                      }
                    />
                    <p>
                      Recognized by CNN.com as one of America’s Best Historic
                      Restaurants
                    </p>
                  </Grid.Column>
                  <Grid.Column width={6} textAlign={"center"}>
                    <Image
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/AlabamaTourism.jpg"
                      }
                    />
                    <p>
                      Bright Star Broiled Seafood Platter named as one of the
                      100 Dishes to Eat Before You Die.
                    </p>
                  </Grid.Column>
                  <Grid.Column width={6} textAlign={"center"}>
                    <Image
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/MSN_2014.png"
                      }
                    />
                    <div>
                      <p>
                        The Bright Star makes MSN’s list of 60 Iconic
                        Restaurants to try before you die.
                      </p>
                      <a
                        href={
                          "http://mystar1017.com/local-alabama-restaurant-in-msns-60-iconic-u-s-restaurants-to-try-before-you-die/?trackback=fbshare_top"
                        }
                        target={"_blank"}
                        className={"read-more-cta"}
                      >
                        READ MORE...
                      </a>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={6} textAlign={"center"}>
                    <a
                      href={
                        "https://www.al.com/life/2023/07/if-they-could-eat-at-one-alabama-restaurant-for-the-rest-of-their-lives-readers-picked-these.html"
                      }
                      target={"_blank"}
                    >
                      <Image
                        centered={true}
                        src={
                          "https://fisherman.gumlet.io/public/7fc034eb-2d62-4b32-919a-024a12dddef7/download.png"
                        }
                      />
                    </a>
                    <p>
                      If they could eat at one Alabama restaurant for the rest
                      of their lives, readers picked these
                    </p>
                  </Grid.Column>
                  <Grid.Column width={6} textAlign={"center"}>
                    <div>
                      <a
                        href={
                          "https://www.tripadvisor.com/Restaurant_Review-g30373-d325995-Reviews-Bright_Star_Restaurant-Bessemer_Alabama.html"
                        }
                        target={"_blank"}
                      >
                        <Image
                          centered={true}
                          src={
                            "https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_vertical.svg"
                          }
                        />
                      </a>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        link
        type
      }
      aboutMarkdown
      primaryLocation {
        phoneNumber
      }
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    externalOrderOnlinePage: fishermanBusinessWebsitePage(
      title: { eq: "Order Online" }
      pageType: { eq: "External" }
    ) {
      url
    }
  }
`;
